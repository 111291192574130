ul {
  list-style: none;
}

.split {
  fill: rebeccapurple;
}

.democratic {
  fill: dodgerblue;
}

.republican {
  fill: crimson;
}

#download-button {
  margin: 10px auto;
  border: none;
  background: none;
  font-color: blue;
  font-size: 36px;
  cursor: pointer;
}

#download-button:active {
  background: #eeeeee;
  border: 1px dotted blue;
}

#map-display {
  display: flex;
  flex-direction: column;
}

#dashboard {
  display: flex;
  flex-direction: row;
}

#map {
  background-color: paperwhite;
}

#results {
  max-width: calc(100vw - 959px);
}

.state {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
